<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        <Button icon="pi pi-print" @click="printPage()" label="طباعه" />
      </template>

      <template #right>
        <InputText
          type="number"
          v-model="search.code"
          style="width: 20%;"
          placeholder="بحث بالكود"
        />
        <InputText
          v-model="search.name"
          style="width: 20%;margin-right: 9px;"
          placeholder="بحث بالاسم"
        />
        <InputText
          v-model="search.warehouseName"
          style="width: 20%;margin-right: 9px;"
          placeholder="بحث بالمستودع"
        />
        <Calendar
          placeholder="من"
          v-model="search.start"
          style="width: 20%;margin-right: 9px;"
          dateFormat="dd/mm/yy"
        />
        <Calendar
          style="margin-right: 9px;width: 20%;"
          placeholder="الى"
          v-model="search.end"
          dateFormat="dd/mm/yy"
        />
        <Button
          style="margin-right: 9px;"
          class="p-button-success"
          icon="pi pi-search"
          @click="searchAdd()"
          v-tooltip="'بحث'"
        />
        <Button
          style="margin-right: 9px;"
          class="p-button-help"
          icon="pi pi-download"
          @click="exportCSV($event)"
          v-tooltip="'طباعه'"
        />
      </template>
    </Toolbar>
    <div id="print">
      <div v-for="itm of list" :key="itm.id">
        <DataTable
          ref="dt"
          :value="itm.list"
          class="p-datatable-customers table table-striped"
          dataKey="id"
          :filters="filters"
          :loading="loading"
        >
          <template #header>
            <div class="table-header">
              {{ itm.name }}
            </div>
          </template>
          <template #empty>
            لا يوجد بيانات
          </template>
          <template #loading>
            يتم تحميل البيانات. يرجي الانتظار...
          </template>

          <Column
            field="code"
            header="الكود"
            filterMatchMode="contains"
            :sortable="true"
          >
            <!-- <template #filter>
              <InputText
                type="number"
                v-model="filters['storeItemsId.code']"
                class="p-column-filter"
                placeholder="بحث بالكود"
              />
            </template> -->
          </Column>
          <Column
            field="storeItemsId.name"
            header="الاسم"
            filterMatchMode="contains"
            :sortable="true"
          >
            <!-- <template #filter>
              <InputText
                v-model="filters['storeItemsId.name']"
                class="p-column-filter"
                placeholder="بحث بالاسم"
              />
            </template> -->
          </Column>
          <Column
            field="date"
            header="تاريخ المعامله"
            filterMatchMode="contains"
            :sortable="true"
          >
            <template #body="slotProps">
              {{ $durationFormatFull(slotProps.data.date) }}
            </template>
            <template #filter>
              <InputText
                type="date"
                v-model="filters['date']"
                class="p-column-filter"
                placeholder="بحث بتاريخ المعامله"
              />
            </template>
          </Column>

          <Column
            field="inventoryType"
            header="نوع المعامله"
            filterMatchMode="contains"
            :sortable="true"
          >
            <template #body="slotProps">
              <p v-if="slotProps.data.inventoryType == 'inventory_adjustment'">
                جرد مخزني
              </p>
              <p
                v-if="
                  slotProps.data.inventoryType == 'inventory_damage_store_items'
                "
              >
                اتلاف مخزني
              </p>
              <p
                v-if="
                  slotProps.data.inventoryType == 'inventory_direct_transfer'
                "
              >
                تحويل مخزني
              </p>
              <p v-if="slotProps.data.inventoryType == 'opening_balance'">
                رصيد افتتاحي
              </p>
              <p v-if="slotProps.data.inventoryType == 'store_counting'">
                صرف مخزني
              </p>
              <p v-if="slotProps.data.inventoryType == 'receiving_inventory'">
                استلام مخزني
              </p>
            </template>

            <!-- <template #filter>
              <select
                class="form-select form-control p-column-filter"
                id="inventoryType"
                name="inventoryType"
                v-model="filters['inventoryType']"
                placeholder="بحث بنوع المعامله"
              >
                <option :value="null">الكل </option>

                <option value="inventory_adjustment">
                  جرد مخزني
                </option>
                <option value="inventory_damage_store_items">
                  اتلاف مخزني
                </option>
                <option value="inventory_direct_transfer">
                  تحويل مخزني
                </option>
                <option value="opening_balance">
                  رصيد افتتاحي
                </option>
                <option value="store_counting">
                  صرف مخزني
                </option>
                <option value="receiving_inventory">
                  استلام مخزني
                </option>
              </select>
            </template> -->
          </Column>

          <Column
            field="warehouseId.name"
            header="المستودع"
            filterMatchMode="contains"
            :sortable="true"
          >
            <!-- <template #filter>
              <InputText
                v-model="filters['warehouseId.name']"
                class="p-column-filter"
                placeholder="بحث بالمستودع"
              />
            </template> -->
          </Column>
          <Column
            field="quantity"
            header="الرصيد"
            filterMatchMode="contains"
            :sortable="true"
          >
            <!-- <template #filter>
              <InputText
                v-model="filters['quantity']"
                class="p-column-filter"
                placeholder="بحث بالرصيد"
              />
            </template> -->

            <template #body="slotProps">
              {{ slotProps.data.quantity.toFixed(2) }}
            </template>
          </Column>
          <!-- 
      <Column
        field="oldQuantity"
        header="الكميه السابقه"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            v-model="filters['oldQuantity']"
            class="p-column-filter"
            placeholder="بحث بالكميه السابقه"
          />
        </template>
      </Column>

      <Column
        field="quantity"
        header="الكميه"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            v-model="filters['quantity']"
            class="p-column-filter"
            placeholder="بحث بالكميه"
          />
        </template>
      </Column> -->
          <template #footer>
            الاجمالي
            {{ calc(itm.list).toFixed(2) }}
          </template>
        </DataTable>

        <br /><br /><br />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      selectedItems: [],
      search: {
        start: null,
        end: null,
        code: null,
        name: null,
      },
      listx: [],

      filters: {},
      filters2: {},
      loading: true,
    };
  },
  methods: {
    printPage() {
      // Get HTML to print from element
      const prtHtml = document.getElementById('print').innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=1024,height=900,toolbar=0,scrollbars=0,status=0',
      );

      WinPrint.document.write(this.$htmlPrint(stylesHtml, prtHtml));

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    searchAdd() {
      let list = [...this.listx];

      if (this.search.start && !this.search.end) {
        const start = new Date(this.search.start).getTime();
        list = list.filter((item) => {
          return new Date(item.createdAt).getTime() >= start;
        });
      }

      if (!this.search.start && this.search.end) {
        const end = new Date(this.search.end).getTime();
        list = list.filter((item) => {
          return new Date(item.createdAt).getTime() <= end;
        });
      }

      if (this.search.start && this.search.end) {
        const start = new Date(this.search.start).getTime();
        const end = new Date(this.search.end).getTime();
        list = list.filter((item) => {
          return (
            new Date(item.createdAt).getTime() >= start &&
            new Date(item.createdAt).getTime() <= end
          );
        });
      }
      if (this.search.code) {
        list = list.filter((item) => {
          return String(item.code) == String(this.search.code);
        });
      }
      if (this.search.name) {
        list = list.filter((item) => {
          return String(item.storeItemsId.name).includes(this.search.name);
        });
      }

      if (this.search.warehouseName) {
        const xx = [];
        for (const item of list) {
          if (
            item.warehouseId &&
            String(item.warehouseId.name).includes(this.search.warehouseName)
          ) {
            xx.push(item);
          }
        }
        list = xx;
      }

      const xlist = [];

      for (const item of list) {
        const addNew = true;
        for (const itm of xlist) {
          if (item.storeItemsId.id == itm.id) {
            addNew = false;
            itm.list.push(item);
          }
        }
        if (addNew) {
          xlist.push({
            ...item.storeItemsId,
            list: [item],
          });
        }
      }
      this.list = xlist;
    },
    calc(list) {
      const li = list.map((itm) => itm.quantity);
      let xNum = 0;
      for (const item of li) {
        xNum = xNum + item;
      }
      return xNum;
    },
    getData() {
      this.$http.get(`storeItems/storeItemsHostoryQuantity`).then(
        (response) => {
          this.loading = false;
          this.listx = response.data;
          const list = response.data;
          const xlist = [];

          for (const item of list) {
            const addNew = true;
            for (const itm of xlist) {
              if (item.storeItemsId.id == itm.id) {
                addNew = false;
                itm.list.push(item);
              }
            }
            if (addNew) {
              xlist.push({
                ...item.storeItemsId,
                list: [item],
              });
            }
          }
          this.list = xlist;
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },

    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    deleteAll() {
      this.$confirm.require({
        message: 'هل انتا متاكد انك تريد حذف السجلات',
        header: 'تأكيد الحذف',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$http
            .delete(`storeItems/deleteList`, {
              idList: this.selectedItems,
            })
            .then(
              () => {
                this.getData();
                this.$toast.add({
                  severity: 'error',
                  summary: 'تم بنجاح',
                  detail: 'تم الحذف بنجاح',
                  life: 3000,
                });
              },
              (err) => {
                this.$toast.add({
                  severity: 'error',
                  summary: 'هناك خطأ',
                  detail: err.response.data.message,
                  life: 3000,
                });
              },
            );
        },
      });
    },
    deleteItem(id) {
      this.$confirm.require({
        message: 'هل انتا متاكد انك تريد حذف السجلات',
        header: 'تأكيد الحذف',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$http.delete(`storeItems/${id}`).then(
            () => {
              this.getData();
              this.$toast.add({
                severity: 'error',
                summary: 'تم بنجاح',
                detail: 'تم الحذف بنجاح',
                life: 3000,
              });
            },
            (err) => {
              this.$toast.add({
                severity: 'error',
                summary: 'هناك خطأ',
                detail: err.response.data.message,
                life: 3000,
              });
            },
          );
        },
      });
    },
  },
  created() {
    if (!this.$checkRoles('reportsInventoryStoreItemsInfoTwo')) {
      this.$router.push('/admin/');
    }

    this.getData();
  },
};
</script>
